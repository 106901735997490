import React, { useState } from 'react';
import {
  CustomMessagesDataTable,
  PlanDocumentsDataTable,
} from '@src/components';
import { Tabs } from '@abyss/web/ui/Tabs';
import { Router } from '@abyss/web/ui/Router';
import { Button } from '@abyss/web/ui/Button';
import { useOverlay } from '@abyss/web/hooks/useOverlay';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { useCustomMessages, usePlanDocuments } from '@src/hooks';
import { CustomMessageSegment, PlanDocumentSegment, Segment } from '@src/types';
import { Divider } from '@abyss/web/ui/Divider';
import { Text } from '@abyss/web/ui/Text';

export const App = ({ accessToken }: { accessToken: string | null }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [
    {
      data: customMessagesData,
      fetching: customMessagesFetching,
      error: customMessagesError,
    },
  ] = useCustomMessages({
    pause: !accessToken,
  });

  const [
    {
      data: planDocumentsData,
      fetching: planDocumentsFetching,
      error: planDocumentsError,
    },
  ] = usePlanDocuments({
    pause: !accessToken,
  });

  const dedupePolicyNumbers = (policyNumbers: string[]): string[] => {
    return [...new Set(policyNumbers)];
  };

  const generatePolicyNumberCell = (
    value: PlanDocumentSegment[] | Segment[]
  ): JSX.Element => {
    const policyNumbers = dedupePolicyNumbers(
      value
        .map((segment) => segment.policyNumber)
        .filter((policyNumber) => !!policyNumber) as string[]
    );

    const isMultiplePolicies = policyNumbers.length > 1;

    return (
      <React.Fragment>
        {policyNumbers[0]}
        {isMultiplePolicies && (
          <Button
            variant="tertiary"
            size="xs"
            onClick={() => drawer.open({ value: policyNumbers })}
            aria-haspopup="dialog"
            style={{ marginLeft: '0.5rem', fontSize: '0.75rem' }}
          >
            See All
          </Button>
        )}
      </React.Fragment>
    );
  };

  const generateProductIdCell = (
    value: PlanDocumentSegment[]
  ): string | JSX.Element[] => {
    const noProductId = value.every((segment) => !segment?.productIds?.length);

    if (noProductId) return 'N/A';

    const segmentsWithProductIds = value.filter(
      (segment) => segment?.productIds?.length > 0
    );

    return segmentsWithProductIds.map(({ productIds }, index) => {
      return (
        <React.Fragment>
          {productIds.join(', ')}
          {index !== segmentsWithProductIds.length - 1 && <Divider />}
        </React.Fragment>
      );
    });
  };

  const generateFundingArrangementCell = (
    value: PlanDocumentSegment[] | CustomMessageSegment[]
  ): string | JSX.Element[] => {
    const noSitusStatesOrFundingArrangements = value.every((segment) => {
      return !(
        segment.situsStates?.length || segment.fundingArrangements?.length
      );
    });

    if (noSitusStatesOrFundingArrangements) return 'N/A';

    const segmentsWithFundingArrangements = value.filter(
      (segment) =>
        segment.fundingArrangements?.length > 0 ||
        segment.situsStates?.length > 0
    );

    return segmentsWithFundingArrangements.map(
      ({ fundingArrangements, situsStates }, index) => {
        return (
          <React.Fragment>
            {!!fundingArrangements?.length && (
              <div>
                <Text size="xs">
                  <b>Funding Arrangements:</b> {fundingArrangements.join(', ')}
                </Text>
              </div>
            )}
            {!!situsStates?.length && (
              <div>
                <Text size="xs">
                  <b> Situs States:</b> {situsStates.join(', ')}
                </Text>
              </div>
            )}

            {index !== segmentsWithFundingArrangements.length - 1 && (
              <Divider />
            )}
          </React.Fragment>
        );
      }
    );
  };

  const drawer = useOverlay('cellOverflow-drawer');
  const { data: drawerData } = drawer.getState();

  if (!accessToken || customMessagesFetching || planDocumentsFetching)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <LoadingSpinner
          size="$lg"
          isLoading={
            !accessToken || customMessagesFetching || planDocumentsFetching
          }
          ariaLoadingLabel="Loading dashboard..."
        />
      </div>
    );

  return (
    <React.Fragment>
      <Router.MetaTags title="Home" />
      <Tabs title="Author App" active={activeTab} onTabChange={setActiveTab}>
        <Tabs.Tab label="Plan Documents">
          <PlanDocumentsDataTable
            data={planDocumentsData?.planDocuments}
            fetching={planDocumentsFetching}
            error={planDocumentsError}
            drawerData={drawerData}
            dedupePolicyNumbers={dedupePolicyNumbers}
            generatePolicyNumberCell={generatePolicyNumberCell}
            generateProductIdCell={generateProductIdCell}
            generateFundingArrangementCell={generateFundingArrangementCell}
          />
        </Tabs.Tab>
        <Tabs.Tab label="Custom Messages">
          <CustomMessagesDataTable
            data={customMessagesData?.customMessages}
            fetching={customMessagesFetching}
            error={customMessagesError}
            drawerData={drawerData}
            dedupePolicyNumbers={dedupePolicyNumbers}
            generatePolicyNumberCell={generatePolicyNumberCell}
            generateProductIdCell={generateProductIdCell}
            generateFundingArrangementCell={generateFundingArrangementCell}
          />
        </Tabs.Tab>
      </Tabs>
    </React.Fragment>
  );
};
