import { gql, useMutation } from 'urql';
import type { CustomMessage } from '@src/types';

export const useCreateCustomMessage = () => {
  const createCustomMessage = gql`
    mutation CreateCustomMessage($input: CreateCustomMessageInput!) {
      createCustomMessage(input: $input) {
        id
        environments
        properties {
          metadata {
            value {
              activationDate
              deactivationDate
              segments {
                policyNumber
                pvrCodes
                fundingArrangements
                segmentId
                situsStates
                productIds
              }
            }
          }
          elements {
            title {
              value
            }
            body {
              value
            }
          }
        }
      }
    }
  `;

  return useMutation<CustomMessage, { input: CustomMessage }>(
    createCustomMessage
  );
};
