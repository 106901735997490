import { UseQueryArgs, UseQueryResponse, gql, useQuery } from 'urql';
import type { CustomMessage } from '@src/types';

export const useCustomMessages = (
  options?: Partial<UseQueryArgs>
): UseQueryResponse<{ customMessages: CustomMessage[] }> => {
  const customMessages = gql`
    query CustomMessages {
      customMessages {
        id
        environments
        properties {
          metadata {
            value {
              activationDate
              deactivationDate
              segments {
                fundingArrangements
                productIds
                policyNumber
                pvrCodes
                segmentId
                situsStates
              }
            }
          }
          elements {
            title {
              value
            }
            body {
              value
            }
          }
        }
      }
    }
  `;

  return useQuery({
    query: customMessages,
    ...options,
  });
};
